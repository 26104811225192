/* global app */

'use strict';

app.constant('config',
        {
            ws: 'wss://enterprise.percipio-big-data.com/websocket',
            previewUrl: 'https://enterprise.percipio-big-data.com',
            stripe: 'pk_test_b6fwpa8asKMbRC7Nn0TkyR72002ArKRCNE',
            format:{
                shortDate: "yyyy-MM-dd HH:mm",
                scopeDate: "yyyy-MM-dd",
                date: "yyyy-MM-dd",
                d3Date: "%d.%m.%Y"
            },
            wordCloud: {
                searchSize: 1000,
                cloudSize: 60,
                maxCorrelation: 300000,
                normalFontSize: [12,40],
                minimalFontSize: 10,
                maximalFontSize: 50,
                minFontSize: 12,
                wordPadding: 5,
                cloudAreaRange: [0.20,0.22]
            },
            treemap:{
                chindlenSize: 6,
            },
            complexity:{
                searchSize1: 1000,
                filterSize: 60,
                searchSize2: 60,
                displaySize: 30
            },
            infiniteList: {
                journalLength: 50,
                authorLength: 80,
                abstractLength: 300
            },
            socialList: {
                authorLength: 80,
                abstractLength: 300
            },
            timelineList: {
            },
            mainToolsMenu: {
                searchSize: 20
            },
            calls: {
                WS_LOGIN: "ws-login",
                WS_LOGIN_INIT: "ws-login-init",
                WS_LOGIN_ANONYMOUS: "ws-login-anonymous",
                WS_WORD_CLOUD: "ws-word-cloud",
                WS_INFINITE_LIST: "ws-infinite-list",
                WS_SOCIAL_LIST: "ws-social-list",
                WS_WORD_SEARCH: "ws-word-search",
                WS_TIMELINE_LIST: "ws-timeline-list",
                WS_ADMIN_DATA: "ws-admin-data",
                WS_ADMIN_CURRENT: "ws-admin-current",
                WS_ADMIN_HISTORY: "ws-admin-history",
                WS_ADMIN_SOURCES: "ws-admin-sources",
                WS_ADMIN_SOURCES_BY_ID: "ws-admin-sources-by-id",
                WS_ADMIN_SOURCE_STAT: "ws-admin-source-stat",
                WS_ADMIN_UI_TEMPLATES: "ws-admin-ui-tempates",
                WS_SCOPES: "ws-scopes",
                WS_EDIT_SCOPE: "ws-edit-scope",
                WS_EDIT_SOURCE: "ws-edit-source",
                WS_EDIT_SOURCE_CHECK_URL: "ws-edit-source-check-url",
                WS_EDIT_ORG_FROM_SOURCE: "ws-edit-org-from-source",
                WS_REMOVE_SOURCE_FROM_ORG: "ws-remove-source-from-org",
                WS_EDIT_ORGANIZATION: "ws-edit-organization",
                WS_EDIT_ORG_FROM_CHANNEL: "ws-edit-org-from-channel",
                WS_EDIT_USER: "ws-edit-user",
                WS_EDIT_USER_UI_TEMPLATES: "ws-edit-user-ui-templates",
                WS_DELETE_ORGANIZATION: "ws-delete-organization",
                WS_DELETE_USER: "ws-delete-user",
                WS_EDIT_CASE: "ws-edit-case",
                WS_EDIT_TWITTER_CHANNEL: "ws-edit-twitter-channel",
                WS_REMOVE_TWITTER_CHANNEL_1: "ws-remove-twitter-channel-1",
                WS_REMOVE_TWITTER_CHANNEL_2: "ws-remove-twitter-channel-2",
                WS_EDIT_UI_TEMPLATE: "ws-edit-ui-template",
                WS_SIGNUP: "ws-signup",
                WS_CAPTCHA: "ws-captcha",
                WS_UPDATE_PASSWD: "ws-update-passwd",
                WS_SWITCH_CASE: "ws-switch-case",
                WS_SWITCH_TWITTER_CHANNEL: "ws-switch-twitter-channel",
                WS_WEBSHOT: "ws-webshot",
                WS_GET_ES: "ws-get-es",
                WS_EXPORT: "ws-export",
                WS_MTM_GET_SOURCES: "ws_mtm_get_sources",
                WS_AGGREGATION: "ws-aggregation",
                WS_GET_CHECKOUT_SESSION: "ws-get-checkout-session",
                WS_SET_CHECKOUT_STATUS: "ws-set-checkout-status"
            },
            moment: {
                longDateFormat : {
                    LT: "HH:mm",
                    LTS: "HH:mm:ss",
                    L: "YYYY-MM-DD",
                    l: "YYYY-MM-DD",
                    LL: "MMMM Do YYYY",
                    ll: "MMM D YYYY",
                    LLL: "L LT",
                    lll: "L LT",
                    LLLL: "dddd, MMMM Do YYYY LT",
                    llll: "ddd, MMM D YYYY LT"
                },
                calendar : {
                    lastDay : '[Yesterday at] LT',
                    sameDay : '[Today at] LT',
                    nextDay : '[Tomorrow at] LT',
                    lastWeek : '[last] dddd [at] LT',
                    nextWeek : 'dddd [at] LT',
                    sameElse : 'LLL'
                }
            }
        }
);
