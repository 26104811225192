app.directive('socialDetails',
    [
        'ThriftHelper', 'config', '$timeout', '$stateParams', 'HitModel', 'ExportService', 'exportableSocialDetailsHitModelDecorator',
        'DashboardService',
        function (ThriftHelper, config, $timeout, $stateParams, HitModel, ExportService, exportableSocialDetailsHitModelDecorator,
                  DashboardService) {
            return {
                restrict: "E",
                replace: true,
                templateUrl: 'app/directives/social-details/social-details.html',
                scope: {
                    options: "="
                },
                link: function (scope, element, attrs) {
                    scope.service = DashboardService;
                    scope.id = "social-details-" + UUID.generate();
                    scope.item = null;
                    scope.previewImage = null;
                    scope.imageId = "social-details-image" + UUID.generate();
                    scope.previewImageFullId = "social-details-image-full-div" + UUID.generate();
                    scope.timer = null;
                    scope.loading = true;
                    scope.generating = false;
                    scope.error = null;
                    scope.showFullImage = false;
                    scope.user = {
                        loading: true,
                        error: undefined,
                        data: {}
                    };

                    var wsWebshot = function () {
                        return config.calls.WS_WEBSHOT + "-" + scope.id;
                    };

                    var wsGetEs = function () {
                        return config.calls.WS_GET_ES + "-" + scope.id;
                    };

                    var eventId = 'onSocialSelected_' + scope.options.detailsId;
                    scope.$on(eventId, function (event, item) {
                        selectDocument(item);
                    });

                    scope.$on(wsWebshot(), function (event, args) {
                        if (args && args.data) {
                            $timeout(function () {
                                if (args.data.status == WebshotStatus.VALID) {
                                    scope.previewImage = 'https://enterprise.percipio-big-data.com' + args.data.webUrl;

                                    scope.loading = false;
                                    scope.generating = false;
                                    scope.error = null;
                                } else if (args.data.status == WebshotStatus.PENDING) {
                                    loadDocumentAfterTimer();

                                    scope.loading = false;
                                    scope.generating = true;
                                    scope.error = null;
                                } else {
                                    scope.loading = false;
                                    scope.generating = false;
                                    scope.error = 'Unable to generate preview.';
                                }
                            });
                        }
                    });

                    scope.$on(wsGetEs(), function (event, args) {
                        $timeout(function () {
                            scope.user.loading = false;
                            if (args && args.data && args.data.twitterUsers && args.data.twitterUsers != null && args.data.twitterUsers.length > 0) {
                                scope.user.data = args.data.twitterUsers[0];
                                scope.user.error = undefined;
                            } else {
                                scope.user.error = 'No user found.';
                            }
                        });
                    });

                    function selectDocument(item) {
                        scope.item = item;
                        scope.previewImage = null;

                        cancelTimer();

                        scope.loading = true;
                        scope.generating = false;
                        scope.error = null;

                        loadPreview(item);

                        var divId = '#' + scope.id;
                        $(divId).animate({ scrollTop: 0 }, 'fast');

                        loadTwitterUser(item.userId);
                    }

                    function loadPreview(document) {
                        if (document.url != null) {
                            ThriftHelper.sendRequest(new GetWebshotReq({
                                    id: document.id,
                                    url: document.url
                                }),
                                MsgType.GET_WEBSHOT_REQ, wsWebshot());
                        }
                    }

                    function cancelTimer() {
                        if (scope.timer !== null) {
                            clearTimeout(scope.timer);
                            scope.timer = null;
                        }
                    }

                    function loadDocumentAfterTimer() {
                        cancelTimer();
                        scope.timer = setTimeout(function () {
                            loadPreview(scope.item);
                        }, 1000);
                    }

                    function loadTwitterUser(id) {
                        scope.user = {
                            loading: true,
                            error: undefined,
                            data: {}
                        };

                        var dto = {
                            type: "twitter",
                            indexName: 'twitter_users',
                            ids: [id]
                        };

                        ThriftHelper.sendRequest(new GetEsReq(dto),MsgType.GET_ES_REQ, wsGetEs());
                    }

                    scope.onShowFullImage = function () {
                        scope.showFullImage = true;
                    };

                    scope.onHideFullImage = function () {
                        scope.showFullImage = false;
                    };
                    
                    scope.selectWord = function (keyword) {
                        var word = 'w:' + keyword;
                        scope.service.onWordClick(word);
                    };

                    scope.selectFos = function (keyword) {
                        var fos = 'f:' + keyword;
                        scope.service.onWordClick(fos);
                    };

                    scope.export = function ($event) {
                        var settings = {
                            item: exportableSocialDetailsHitModelDecorator(scope.item)
                        };
                        ExportService.exportDialog("social-details",settings,$event);
                    };

                    scope.selectDocument = selectDocument;
                }
            };
        }
    ]);